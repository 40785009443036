import request, { ResponsePageableData } from '@/libs/request'

/** 房间列表 */
export const getHouseList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Room/getRoomList', data })

/** 创建房间数据结构 */
export const createHouseRow = () => ({
	id: 0,
	/** 房间号 */
	number: '',
})

/** 房间新增/编辑 */
export const editHouse = (data) => request({ url: '/adminapi/Room/editRoom', data })

/** 房间删除 */
export const delHouse = (data?) => request({ url: '/adminapi/Room/delRoom', data })

/** 房态类型列表 */
export const getHouseState = (data?) => request<ResponsePageableData>({ url: '/adminapi/Room/getRommState', data })

/** 设置房间房态 */
export const setHouseState = (data?) => request<ResponsePageableData>({ url: '/adminapi/Room/setRommState', data })
