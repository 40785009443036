





























































































































import Vue from 'vue'
import { cloneDeep } from 'lodash'
import { useTable } from '@/components/table/table-hook'
import SelectHotel from '@/components/select/select-hotel.vue'
import { getOrderList } from '@/api/breakfast-order'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { mapState } from 'vuex'
import { cancel, refundDetail, refundOrders, detail } from '@/api/hotel-order'
export default Vue.extend({
	components: { SelectHotel },
	data() {
		return {
			order_id: '' as any,
			tetxs: { 0: '', 1: '待使用', 2: '已核销', 3: '已退款', 4: '已过期' },
			form: {} as any,
			form1: {} as any,
			gridData: [] as any,
			dialogTableVisible: false,
			refundForm: {
				id: '',
				mobile: 0 as any,
				refund_fee: 0 as any,
				dateList: [] as any,
				refundNum: 0 as any,
			},
			remark: '' as any,
			disabled: false,
			refundDialog: false,
			dialogVisible: false,
			getOrderList,
			...useTable({
				name: '订餐订单',
				formdata: {
					id: 0,
					state: undefined,
				},
				query: {},
				getData: (query) => {
					let n_query = cloneDeep(query)
					console.log(n_query, 'n_query')

					if (n_query['datetimes']) {
						n_query['start_pay_time'] = n_query['datetimes'][0]
						n_query['end_pay_time'] = n_query['datetimes'][1]
					} else if (n_query['datetimes'] == null) {
						n_query['datetimes'] = []
						n_query['start_pay_time'] = ''
						n_query['end_pay_time'] = ''
						n_query['is_export'] = 0
					}
					return getOrderList(n_query).then((res) => res.data)
				},
			}),
			// 	<!-- 已完成、待支付、待使用、使用中、已取消 -->
			options: [
				{ value: 0, label: '待支付' },
				{ value: 1, label: '待使用' },
				{ value: 2, label: '使用中' },
				{ value: 3, label: '已完成' },
				{ value: 4, label: '已取消' },
			],
		}
	},

	methods: {
		detail(id) {
			this.dialogTableVisible = true
			detail({
				order_id: id,
			}).then((res) => {
				console.log(res)
				this.gridData = res.data.breakfast.checks
			})
		},
		close() {
			this.gridData = []
		},
		closeRefundDialog() {
			// this.refundForm = {
			// 	id: '',
			// 	room_no: '',
			// 	time_flag: '2',
			// 	refund_pay_fee: '',
			// 	refund_balance: '',
			// 	refund_integral: '',
			// 	refund_saler_balance: '',
			// 	breakfast_refund_pay_fee: '',
			// 	breakfast_refund_balance: '',
			// 	stay_ids: [],
			// 	break_no: '',
			// 	breakfast_refund_num: '',
			// 	breakfast_refund_num_show: '',
			// }
			this.refund_total_pay_fee = 0
			this.breakfast_refund_total_pay_fee = 0
			this.refund_total_integral = 0
			this.refund_total_balance = 0
			this.breakfast_refund_total_balance = 0
			this.refund_total_saler_balance = 0
			this.refund_total_price = 0
			this.breakfast_refund_total_price = 0
		},
		// 取消订单
		cancel(row) {
			this.$confirm('确定对订单号为' + row.out_trade_no + '进行取消操作吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					cancel({ order_id: row.id }).then((res) => {
						if (res.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功!',
								onClose: () => {
									this.tableGetData()
								},
							})
						} else {
							this.$message.error(res.msg)
						}
					})
				})
				.catch(() => {})
		},
		refundSubmit() {
			this.disabled = true
			refundOrders({
				order_id: this.order_id,
				remark: this.remark,
			}).then((res) => {
				if (res.code == 0) {
					this.refundDialog = false
					this.$message({
						type: 'success',
						message: '操作成功!',
						onClose: () => {
							this.tableGetData()
						},
					})
				} else {
					this.disabled = false
					this.$message.error(res.msg)
				}
			})
		},
		//订单退款
		refundBreak(row) {
			this.disabled = false
			this.order_id = row.id
			refundDetail({
				order_id: row.id,
			}).then((res) => {
				console.log(res.data.breakfast)
				this.refundForm.mobile = res.data.mobile
				this.refundForm.dateList = res.data.breakfast.dateList
				this.refundForm.refund_fee = res.data.breakfast.refund_fee / 100
				this.refundForm.refundNum = res.data.breakfast.refundNum
			})
			this.refundDialog = true
			console.log('订单退款')
		},
		//确定订单退款

		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		inportexcel() {
			this.dialogVisible = true
		},
		que() {
			setTimeout(() => {
				this.table_query.is_export = 0
			}, 500)
			this.dialogVisible = false
			this.table_query.is_export = 1
			if (this.table_query['datetimes']) {
				this.table_query['start_pay_time'] = this.table_query['datetimes'][0]
				this.table_query['end_pay_time'] = this.table_query['datetimes'][1]
			} else if (this.table_query['datetimes'] == null) {
				this.table_query['start_pay_time'] = ''
				this.table_query['end_pay_time'] = ''
			}
			this.table_query['sort_key'] = 'created_at'
			getOrderList(this.table_query).then((res) => {
				this.export_data = res.data.rows
				this.export_data.forEach((it) => {
					if (it.user) {
						it.nick_name = it.user.nick_name
					}
					if (it.hotel) {
						it.hotel_name = it.hotel.name
					}
					it.price = it.price / 100
					it.pay_fee = it.pay_fee / 100
					if (it.state == 1) {
						it.state = '已完成'
					} else if (it.state == 2) {
						it.state = '已退款'
					} else {
						it.state = '-'
					}
				})
				//兼容ie10哦！
				require.ensure([], () => {
					const tHeader = [
						'用户昵称',
						'酒店',
						'下单日期',
						'早餐日期',
						'房间号码',
						'商户订单号',
						'平台支付单号',
						'早餐份数',
						'早餐原价',
						'折扣',
						'支付金额',
						'支付时间',
						'状态',
					] //将对应的属性名转换成中文
					// const tHeader = [];
					const filterVal = [
						'nick_name',
						'hotel_name',
						'created_at',
						'date',
						'room_num',
						'out_trade_no',
						'transaction_id',
						'num',
						'price',
						'discount_rate',
						'pay_fee',
						'pay_time',
						'state',
					] //table表格中对应的属性名
					const list = this.export_data
					const data = this.formatJson(filterVal, list)
					this.is_export = 0
					export_json_to_excel(tHeader, data, '早餐订单')
				})
			})
		},
	},
	created() {
		this.tableGetData()
	},
	computed: {
		...mapState<any>('order', {
			mealGoodsState: (state) => state.mealGoodsState,
		}),
	},
})
