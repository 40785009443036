





import Vue from 'vue'
import SelectMixin from './select-mixin'
import { mapState } from 'vuex'

/**
 * 订单状态选择器
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选择订单状态',
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState<any>('order', {
			hotelOrderState: (state) => state.hotelOrderState,
		}),
	},
})
