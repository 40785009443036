


















































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { geOrderIntegralMallList } from '@/api/integral-mall-goods-order'
import { mapState } from 'vuex'
export default Vue.extend({
	data() {
		return {
			...useTable({
				name: '订餐订单',
				formdata: {
					id: 0,
					state: undefined,
				},
				query: {},
				getData: (query) => geOrderIntegralMallList(query).then((res) => res.data),
			}),
		}
	},

	methods: {
		edit(row) {
			;(this.$refs.formDialogRef as any).open({ id: row.id, state: undefined })
		},
	},
	created() {
		this.tableGetData()
	},
	computed: {
		...mapState<any>('order', {
			mealGoodsState: (state) => state.mealGoodsState,
		}),
	},
})
