






































































































































































































































import Vue from 'vue'
import SelectHotelOrderState from '@/components/select/select-hotel-remark.vue'
import SelectBalanceType from '@/components/select/select-integral-remark.vue'
import SelectHotel from '@/components/select/select-hotel.vue'
import SelectHouse from '@/components/select/select-house.vue' //房间选择器
import { useTable } from '@/components/table/table-hook'
import { getOrderHotelList, editOrderHotel, reply, delOrderHotelEvaluation } from '@/api/remark_hotel'
import { getTreeName } from '@/libs/vue-tools/index'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'
import { export_json_to_excel } from '@/vendor/Export2Excel'
export default Vue.extend({
	components: { SelectHotelOrderState, SelectHouse, SelectHotel, SelectBalanceType },
	data() {
		return {
			// scoreData: [] as any,
			scoreTotal: '' as any,
			textarea: '',
			is_export: 0,
			dialogVisible: false,
			dialog: false,
			uid: 0,
			evaluation_reply_id: 0,
			export_data: [] as any,
			editOrderHotel,
			...useTable({
				name: '点评',
				formdata: {
					id: 0,
					room_id: undefined,
				},
				query: {},

				getData: (query) => {
					let n_query = cloneDeep(query)
					if (n_query['datetimes']) {
						n_query['start_time'] = n_query['datetimes'][0]
						n_query['end_time'] = n_query['datetimes'][1]
					} else if (n_query['datetimes'] == null) {
						n_query['datetimes'] = []
						n_query['start_time'] = ''
						n_query['end_time'] = ''
						n_query['is_export'] = 0
					}
					// console.log(n_query)

					// return getOrderHotelList(n_query).then((res) => res.data)

					return getOrderHotelList(n_query).then((res) => res.data)
				},
				delData: (row) => delOrderHotelEvaluation({ id: row.id }),
			}),
		}
	},

	methods: {
		// keepTwoDecimalFull(num) {
		// 	var result = parseFloat(num)
		// 	if (isNaN(result)) {
		// 		// alert('传递参数错误，请检查！')
		// 		return 0
		// 	}
		// 	result = Math.round(num * 100) / 100
		// 	var s_x = result.toString()
		// 	var pos_decimal = s_x.indexOf('.')
		// 	if (pos_decimal < 0) {
		// 		pos_decimal = s_x.length
		// 		s_x += '.'
		// 	}
		// 	while (s_x.length <= pos_decimal + 2) {
		// 		s_x += '0'
		// 	}
		// 	return s_x
		// },
		// clearKeyWords() {
		// console.log(1111)
		// this.scoreData = []
		// this.getList()
		// this.table_query.is_export = 0
		// this.tableGetData()
		// },
		// getList() {
		// 	this.table_query.is_export = 1
		// 	// if (this.table_query['datetimes']) {
		// 	// 	this.table_query['start_time'] = this.table_query['datetimes'][0]
		// 	// 	this.table_query['end_time'] = this.table_query['datetimes'][1]
		// 	// }
		// 	console.log(this.table_query)
		// 	getOrderHotelList(this.table_query).then((res) => {
		// 		res.data.rows.forEach((item) => {
		// 			this.scoreData.push(item.score * 1)
		// 		})
		// 		this.scoreTotal = res.data.total
		// 	})
		// 	console.log(this.scoreData, '=========')
		// },
		// sumArray(arr) {
		// 	return arr.reduce((acc, curr) => acc + curr, 0)
		// },
		// startScore(e) {
		// 	this.scoreData = []
		// 	this.getList()
		// 	this.table_query.is_export = 0
		// 	this.tableGetData()
		// },
		// endScore(e) {
		// 	this.scoreData = []
		// 	this.getList()
		// 	this.table_query.is_export = 0
		// 	this.tableGetData()
		// },
		lookDesc(desc) {
			this.$alert(desc, '消息内容', {
				confirmButtonText: '确定',
			})
		},
		queren() {
			let arrfor = {
				evaluation_id: this.uid,
				id: this.evaluation_reply_id,
				desc: this.textarea,
			}
			reply(arrfor).then((res) => {
				this.tableGetData()
				this.dialogVisible = false
			})
		},
		tuikuan(red) {
			console.log(red)
			if (red.evaluation_reply_id != 0) {
				this.textarea = red.order_hotel_evaluation_reply.desc
				console.log(this.textarea)
			}
			this.dialogVisible = true
			this.uid = red.id
			this.evaluation_reply_id = red.evaluation_reply_id
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		inportexcel() {
			this.dialog = true
		},
		getTreeName,
		edit(row) {
			;(this.$refs.formDialogRef as any).open({ id: row.id, room_id: undefined })
		},
		que() {
			this.dialog = false
			this.table_query.is_export = 1
			if (this.table_query['datetimes']) {
				this.table_query['start_time'] = this.table_query['datetimes'][0]
				this.table_query['end_time'] = this.table_query['datetimes'][1]
			} else if (this.table_query['datetimes'] == null) {
				this.table_query['start_time'] = ''
				this.table_query['end_time'] = ''
			}
			console.log(this.table_query)

			getOrderHotelList(this.table_query).then((res) => {
				this.export_data = res.data.rows
				this.export_data.forEach((it) => {
					it.evaluation_reply_id = it.evaluation_reply_id == 0 ? '无' : it.order_hotel_evaluation_reply.desc
					console.log(it)
					it.hotel_name = it.hotel.name
					it.username = it.user.nick_name
					it.start_date = it.order_hotel.start_date
					it.room_type = it.order_hotel.room_type_snapshot && JSON.parse(it.order_hotel.room_type_snapshot).name
					it.reply_time = it.order_hotel_evaluation_reply ? it.order_hotel_evaluation_reply.create_time : '-'
					it.hotel_reply = it.order_hotel_evaluation_reply ? it.order_hotel_evaluation_reply.desc : '-'
					it.delete_status = it.delete_time == 0 ? '-' : '已删除'
				})
				//兼容ie10哦！
				require.ensure([], () => {
					const tHeader = ['用户昵称', '酒店名称', '入住日期', '点评时间', '入住房型', '点评总分', '点评内容', '状态', '酒店回复内容', '回复时间'] //将对应的属性名转换成中文
					// const tHeader = [];
					const filterVal = [
						'username',
						'hotel_name',
						'start_date',
						'create_time',
						'room_type',
						'score',
						'desc',
						'delete_status',
						'hotel_reply',
						'reply_time',
					] //table表格中对应的属性名
					const list = this.export_data
					const data = this.formatJson(filterVal, list)
					export_json_to_excel(tHeader, data, '点评列表')
				})
			})
		},
		// tableDelRow() {},
	},
	created() {
		console.log(this, '0000000')
		// let query = {
		// 	state: this.table_query.state,
		// 	hotel_id: this.table_query.hotel_id,
		// 	search_word: this.table_query.search_word,
		// }

		this.tableGetData()
	},
	mounted() {
		// this.getList()
	},
	computed: {
		...mapState<any>('order', {
			hotelOrderState: (state) => state.hotelOrderState,
		}),
	},
})
