import Vue, { PropType } from 'vue'
import { cloneDeep } from 'lodash'
export default Vue.extend({
	props: {
		/** 选中的值，支持v-model */
		value: {
			type: [String, Number, Array] as PropType<(number | string)[] | number | string | null>,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			rows: [] as any[],
			total: 0,
		}
	},
	methods: {
		getData() {
			// @ts-ignore
			if (!this.api) return
			this.loading = true
			// @ts-ignore
			const query = this.query || {}
			// @ts-ignore
			return this.api(query)
				.then(({ data }) => {
					this.rows = data.rows
					this.total = data.total
					this.$emit('load', cloneDeep(data.rows), data.total)
				})
				.finally(() => (this.loading = false))
		},
		onInput(val) {
			if (JSON.stringify(val) !== JSON.stringify(this.value)) this.$emit('input', val)
		},
	},
	mounted() {
		this.getData()
	},
})
