var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-8 m-4 bg-white rounded vi-hotel-list"},[_c('TableHead',{attrs:{"search-word":_vm.table_query.search_word,"searchPlaceholder":"搜索订单号"},on:{"update:searchWord":function($event){return _vm.$set(_vm.table_query, "search_word", $event)},"update:search-word":function($event){return _vm.$set(_vm.table_query, "search_word", $event)},"search":function($event){return _vm.tableGetData()},"refresh":function($event){return _vm.tableGetData()}},scopedSlots:_vm._u([{key:"left",fn:function(ref){
var size = ref.size;
return undefined}},{key:"before-right",fn:function(ref){
var size = ref.size;
return undefined}}])}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.table_state.loading),expression:"table_state.loading"}],staticClass:"mt-4",attrs:{"border":"","data":_vm.table_data}},[_c('el-table-column',{attrs:{"label":"序号","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"label":"订单号","prop":"out_trade_no","minWidth":"100"}}),_c('el-table-column',{attrs:{"label":"用户姓名","prop":"nick_name","minWidth":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.user)?[_vm._v(" "+_vm._s(row.user.nick_name)+" ")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"手机号","prop":"mobile","minWidth":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.user)?[_vm._v(" "+_vm._s(row.user.mobile)+" ")]:_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"平台支付单号","prop":"transaction_id","minWidth":"100","formatter":function (ref) {
	var transaction_id = ref.transaction_id;

	return transaction_id || '-';
}}}),_c('el-table-column',{attrs:{"label":"商品/数量","prop":"mobile","minWidth":"100","formatter":function (row) { return row.good_snapshot && JSON.parse(row.good_snapshot).name + ' x ' + row.number; }}}),_c('el-table-column',{attrs:{"label":"支付积分","prop":"pay_fee","minWidth":"100"}}),_c('el-table-column',{attrs:{"label":"支付方式","prop":"pay_type","minWidth":"100","formatter":function (row) { return (row.pay_type == 1 ? '微信' : row.pay_type == 2 ? '支付宝' : '-'); }}}),_c('el-table-column',{attrs:{"label":"支付时间","prop":"pay_time","minWidth":"100"}}),_c('el-table-column',{attrs:{"label":"订单状态","prop":"state","width":"120","formatter":function (ref) {
	var state = ref.state;

	return (state == 1 ? '已完成' : '-');
}}})],1),_c('TablePagination',{attrs:{"query":_vm.table_query,"state":_vm.table_state},on:{"current-change":function($event){return _vm.tableGetData()},"size-change":function($event){;(_vm.table_query.index = 1), _vm.tableGetData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }