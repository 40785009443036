import { assign, cloneDeep } from 'lodash'

export interface OptionType<RowDataType extends object, QueryType extends object, StateType extends object> {
	// /** 模型名 */
	name: string
	/** 模型表单数据 */
	formdata: RowDataType
	/** 表格初始数据 */
	table_data?: Array<RowDataType & { [property: string]: any }>
	table_day?: Array<RowDataType & { [property: string]: any }>

	// 设置为RowDataType并一个任意键名属性，相当于约束了table_data的每个元素，至少是一个 RowDataType ，但也可以有其他任意键
	// 例如有时候在获取数据后想给每条数据增加一些额外属性时，ts无法识别这些属性，但也不会造成访问时报错

	/** 额外表格状态 */
	state?: StateType
	/** 额外查询参数 */
	query?: QueryType
	/** 查询数据方法 */
	getData?: <QueryType extends object>(query: QueryType) => Promise<any>

	/** 删除一条数据方法 */
	delData?: (query: RowDataType | { id: number | string }) => Promise<any>

	/** 取消一条数据方法 */
	canCelData?: (query: RowDataType | { id: number | string }) => Promise<any>
}

export interface DelApiOption {
	/** ID */
	id: number | string
	/** 名称 */
	name?: string
}

export interface CanCelApiOption {
	/** ID */
	id: number | string
	/** 名称 */
	name?: string
}
/**
 * 创建数据模型表格和表单
 */
export const useTable = <RowDataType extends object, QueryType extends object, StateType extends object>(
	option: OptionType<RowDataType, QueryType, StateType>
) => {
	let table_query: QueryType & {
		is_export: number
		/** 页码 */
		index: number
		/** 每页条数 */
		limit: number
		/** 搜索词 */
		search_word: string
	} = assign({}, option.query, {
		index: 1,
		limit: 10,
		search_word: '',
		is_export: 0,
	})

	let table_state: StateType & {
		/** 加载中 */
		loading: boolean
		/** 总条数 */
		total: number
	} = assign({}, option.state, {
		loading: false,
		total: 0,
	})

	return {
		/** 模型名 */
		table_name: option.name,
		/** 表格数据 */
		table_data: option.table_data ?? ([] as RowDataType[]),
		/** 表格数据 */
		table_day: option.table_day ?? ([] as RowDataType[]),
		/** 表格查询参数 */
		table_query,
		/** 表格状态 */
		table_state,
		/** 模型表单数据 */
		table_formdata: cloneDeep(option.formdata) as RowDataType,
		/** 表格获取数据方法 */
		tableGetData(
			/** 是否显示加载中状态 */
			show_loading = true
		): Promise<{
			rows: any[]
			count: any[]
			total: number
		}> {
			if (!option.getData) return Promise.reject('未设置获取数据方法')
			if (this.table_state.loading) return Promise.reject('正在加载中')

			if (show_loading) this.table_state.loading = true
			return option
				.getData<typeof table_query>(this.table_query)
				.then((data) => {
					this.table_state.total = data.total
					this.table_data = data.rows
					this.table_day = data.count
					return cloneDeep(data)
				})
				.finally(() => (this.table_state.loading = false))
		},
		/** 删除单条数据方法 */
		tableDelRow(
			/** 一条模型数据 */
			row: DelApiOption | RowDataType
		) {
			if (!option.delData) return
			// @ts-ignore 由于$confirm是element-ui挂载在根实例的属性，这里的this仅指向这个对象本身，所以访问不到，其实这里的this是指向调用的Vue组件的
			if (this.$confirm)
				// @ts-ignore
				this.$confirm(`确定${row?.name ? `对[${row.name}]` : ''}进行删除操作吗？`, '提示', {
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action != 'confirm') return done()
						instance.confirmButtonLoading = true
						// @ts-ignore 上面已经判断过 option.delData 是存在的，但由于这里嵌入在beforeClose里，ts又笨了...
						option
							.delData({ id: 'id' in row ? row.id : '' })
							.then(() => {
								done()
								this.tableGetData()
							})
							.finally(() => (instance.confirmButtonLoading = false))
					},
				}).catch(() => {})
		},
		/** 取消单条数据方法 */
		tableCancelRow(
			/** 一条模型数据 */
			row: CanCelApiOption | RowDataType
		) {
			if (!option.canCelData) return
			// @ts-ignore 由于$confirm是element-ui挂载在根实例的属性，这里的this仅指向这个对象本身，所以访问不到，其实这里的this是指向调用的Vue组件的
			if (this.$confirm)
				// @ts-ignore
				this.$confirm(`确定${row?.name ? `对[${row.name}]` : ''}进行取消操作吗？`, '提示', {
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action != 'confirm') return done()
						instance.confirmButtonLoading = true
						// @ts-ignore 上面已经判断过 option.delData 是存在的，但由于这里嵌入在beforeClose里，ts又笨了...
						option
							.canCelData({ id: 'id' in row ? row.id : '' })
							.then(() => {
								done()
								this.tableGetData()
							})
							.finally(() => (instance.confirmButtonLoading = false))
					},
				}).catch(() => {})
		},
	}
}
